<template>
  <widget-base
    :loading="loading"
    class="h-full w-full"
    no-data-icon="th-icon-chart"
    icon-size="large"
    :has-data="hasData"
    :title="chartOptions.title"
    :can-refetch="canRefetch"
    @update:refetch="$emit('refetch')"
  >
    <div class="h-full flex justify-between flex-col relative">
      <div class="h-full w-full pb-4">
        <vue-echarts
          :option="getChartOptions"
          :resizable="true"
          style="height: 500px"
        />
      </div>
    </div>
  </widget-base>
</template>

<script>
import { mapGetters } from 'vuex'
import { VueEcharts } from 'vue3-echarts'
import 'echarts/lib/chart/line'
import { currencySymbol } from '@/constants'
import safeGet from 'just-safe-get'
import WidgetBase from '../widget-base'

export default {
  name: 'Revenue',
  components: {
    WidgetBase,
    VueEcharts
  },
  props: {
    chartOptions: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    canRefetch: {
      type: Boolean,
      default: false
    },
    currentUserContext: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters({
      local: 'Config/getLocale'
    }),
    hasData() {
      const data = safeGet(this.chartOptions, ['series', 0, 'data']) || []
      return data.some(([, value]) => value !== null)
    },
    getChartOptions() {
      return {
        animation: false,
        xAxis: {
          name: this.chartOptions.xAxis.name,
          nameLocation: 'center',
          nameGap: 40,
          nameTextStyle: {
            fontSize: 15,
            fontWeight: 'bold'
          },
          type: 'category'
        },
        yAxis: {
          name: this.$t('pages.reports.statistics.overview.revenue', [
            currencySymbol[this.currentUserContext.defaultCurrency]
          ]),
          nameLocation: 'center',
          nameGap: this.getAxisGap(this.chartOptions.series),
          type: 'value',
          nameTextStyle: {
            fontSize: 15,
            fontWeight: 'bold'
          },
          axisLabel: {
            formatter: (value) =>
              this.$formatNumber(value, {
                precision: 0
              })
          }
        },
        tooltip: {
          transitionDuration: 0,
          textStyle: {
            fontWeight: 'bold'
          },
          formatter: ({ seriesName, data }) =>
            `${seriesName}<br />${data[0]}: ${this.$formatCurrency(
              data[1],
              this.currentUserContext.defaultCurrency
            )}`
        },
        series: this.chartOptions.series.map(({ name, data, type }) => ({
          name,
          data,
          type,
          symbolSize: 10,
          areaStyle: {},
          itemStyle: {
            color: getComputedStyle(document.documentElement).getPropertyValue(
              '--graph-highlight-color'
            )
          }
        }))
      }
    }
  },
  methods: {
    getAxisGap(series = []) {
      const data = series?.[0]?.data || []
      const numberOfDigits = data.reduce((max, [, num]) => {
        if (!num) {
          return max
        }
        return Math.max(max, `${num}`.length)
      }, 0)
      return numberOfDigits >= 10 ? '65' : '40'
    }
  }
}
</script>
